import React from 'react';
import {
  Filter, Datagrid, TextField, useTranslate,
} from 'react-admin';

import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Tooltip from '@material-ui/core/Tooltip';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import FormatValueField from '../../components/fields/FormatValueField';
import RestrictedList from '../../components/admin/RestrictedList';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {
  formatDisplayDateTime, formatWaybillTerm, formatWhoCreated, formatOrg, formatDisplayDate
} from '../../utils/formatters';

const EWaybillList = (props) => (
  <RestrictedList
    title="telemedic.ewaybills.label"
    filters={<EWaybillFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField
        label="#"
        source="id"
        sortable
      />
      <TextField
        label="telemedic.ewaybills.list.vehicle"
        source="vehicle.car_license_plate"
        sortable
        sortBy="vehicle"
      />
      <TextField
        label="telemedic.ewaybills.list.fio"
        source="fio"
        sortable={false}
      />
      <FormatValueField
        label="telemedic.ewaybills.list.organization"
        source="organization.name"
        formatter={formatOrg}
        sortable={false}
      />
      <FormatValueField
        label="telemedic.ewaybills.list.waybillTermDate"
        source="waybill_term_date_begin"
        formatter={formatDisplayDate}
      />
      <FormatValueField
        label="telemedic.ewaybills.list.waybillTerm"
        source="waybill_term"
        formatter={formatWaybillTerm}
      />
      <FormatValueField
        label="telemedic.ewaybills.list.whoCreated"
        source="who_created"
        sortable={false}
        formatter={formatWhoCreated}
      />
      <SyncStatusField />
    </Datagrid>
  </RestrictedList>
);

const EWaybillFilter = (props) => {
  const service = useTelemedicService();
  const vehicles = useTelemedicServiceJsonValue(
    () => service.getVehicleValues(null), null, [],
  );
  return (
    <Filter key="waybill_filter" {...props}>
      <AutocompleteInput
        label="telemedic.ewaybills.list.vehicle"
        choices={vehicles}
        choiceLabelField="name"
        choiceValueField="id"
        source="vehicle_id"
        alwaysOn
        minWidth
        resettable
      />
    </Filter>
  );
};

const SyncStatusField = ({ record }) => {
  const translate = useTranslate();
  if (record) {
    if (record.error) {
      return (
        <Tooltip disableFocusListener disableTouchListener title={translate('telemedic.ewaybills.list.error')}>
          <span><PriorityHighIcon color="error" /></span>
        </Tooltip>
      );
    }
    if (record.kis_art_id) {
      const formattedTime = record.sent_at ? formatDisplayDateTime(record.sent_at) : '-'
      const sentAt = `${translate('telemedic.ewaybills.list.sentAt')}: ${formattedTime}`;
      return (
        <Tooltip disableFocusListener disableTouchListener title={sentAt}>
          <span><CheckIcon color="primary" /></span>
        </Tooltip>
      );
    }
  }
  return null;
};

export default EWaybillList;
