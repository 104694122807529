import React from 'react';
import {
  Filter, Datagrid, TextField, useTranslate,
  useDataProvider, downloadCSV
} from 'react-admin';
import * as XLSX from 'xlsx';

import CheckIcon from '@material-ui/icons/Check';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Tooltip from '@material-ui/core/Tooltip';

import AutocompleteInput from '../../components/inputs/AutocompleteInput';
import DateInput from '../../components/inputs/DateInput';
import RestrictedList from '../../components/admin/RestrictedList';
import FormatValueField from '../../components/fields/FormatValueField';
import useTelemedicService from '../../hooks/useTelemedicService';
import useTelemedicServiceJsonValue from '../../hooks/useTelemedicServiceJsonValue';
import {
  formatDisplayDateTime, formatWaybillTerm, formatWhoCreated, formatOrg
} from '../../utils/formatters';

/**
 * Функция для автоматического вычисления ширины столбца.
 * Для каждого столбца (ключа объекта) находим максимальную длину текста среди всех строк (учитывая заголовок) и добавляем небольшое смещение.
 */
const getColumnWidths = (data) => {
  if (!data || data.length === 0) return [];
  const keys = Object.keys(data[0]);
  return keys.map(key => {
    let maxLen = key.length;
    data.forEach(row => {
      const cell = row[key] ? row[key].toString() : "";
      // Если в ячейке переносы строк, ищем самую длинную строку
      const cellLines = cell.split(/\r\n|\r|\n/);
      cellLines.forEach(line => {
        if (line.length > maxLen) maxLen = line.length;
      });
    });
    return { wch: maxLen + 2 }; // добавляем небольшой отступ
  });
};

/**
 * Функция для скачивания Excel файла с нужным расширением.
 */
const downloadXLSX = (blob, fileName) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

const AisErpList = (props) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();

  const exporter = async () => {
    const { data } = await dataProvider.getList('ais_erp_integration', {
      pagination: { page: 1, perPage: 50000 },
      sort: { field: 'datetime_send', order: 'DESC' },
      filter: {}
    });

    const aisErpForExport = data.map(record => ({
      'ID': record.id,
      [translate('telemedic.aisErpIntegration.list.organization')]: record.organization?.name || '',
      [translate('telemedic.aisErpIntegration.list.terminal')]: record.terminal?.name || '',
      [translate('telemedic.aisErpIntegration.list.datetimeSend')]: formatDisplayDateTime(record.datetime_send),
      [translate('telemedic.aisErpIntegration.list.status')]: record.error 
        ? `${translate('telemedic.aisErpIntegration.list.error')} - ${record.error}`
        : record.ais_erp_checkup_id 
          ? translate('telemedic.aisErpIntegration.list.statuses.success')
          : translate('telemedic.aisErpIntegration.list.statuses.pending')
    }));

    const worksheet = XLSX.utils.json_to_sheet(aisErpForExport);
    // Устанавливаем ширину столбцов в зависимости от максимальной длины текста
    worksheet['!cols'] = getColumnWidths(aisErpForExport);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'AIS ERP');
    
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    downloadXLSX(blob, 'ais_erp_integration.xlsx');
  };

  return (
    <RestrictedList
      title="telemedic.aisErpIntegration.label"
      filters={<AisErpFilter />}
      sort={{ field: 'datetime_send', order: 'DESC' }}
      exporter={exporter}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField
          label="#"
          source="id"
          sortable
        />
        <TextField
          label="telemedic.aisErpIntegration.list.organization"
          source="organization.name"
          sortable
          sortBy="organization"
        />
        <TextField
          label="telemedic.aisErpIntegration.list.terminal"
          source="terminal.name"
          sortable
          sortBy="terminal"
          // formatter={formatTerminal}
        />
        <FormatValueField
          label="telemedic.aisErpIntegration.list.datetimeSend"
          source="datetime_send"
          formatter={formatDisplayDateTime}
        />
        <SyncStatusField />
      </Datagrid>
    </RestrictedList>
  );
};

const AisErpFilter = (props) => {
  const translate = useTranslate();
  const service = useTelemedicService();
  const orgs = useTelemedicServiceJsonValue(
    () => service.getOrganizationValues(), null, [],
  );
  const terminals = useTelemedicServiceJsonValue(
    () => service.getTerminalValues(), null, [],
  );
  const statuses = [
    { id: 1, name: translate('telemedic.aisErpIntegration.list.statuses.success') },
    { id: 0, name: translate('telemedic.aisErpIntegration.list.statuses.error') },
  ];
  return (
    <Filter key="ais_erp_integration_filter" {...props}>
      <DateInput
        label="telemedic.aisErpIntegration.list.sendDateFrom"
        source="send_date_from"
        alwaysOn
        minWidth
        resettable
      />
      <DateInput
        label="telemedic.aisErpIntegration.list.sendDateTo"
        source="send_date_to"
        alwaysOn
        minWidth
        resettable
      />
      <AutocompleteInput
        label="telemedic.aisErpIntegration.list.organizations"
        choices={orgs}
        choiceLabelField="name"
        choiceValueField="id"
        source="organization_id"
        alwaysOn
        minWidth
        resettable
      />
      <AutocompleteInput
        label="telemedic.aisErpIntegration.list.terminals"
        choices={terminals}
        choiceLabelField="name"
        choiceValueField="id"
        source="terminal_id"
        alwaysOn
        minWidth
        resettable
      />
      <AutocompleteInput
        label="telemedic.aisErpIntegration.list.status"
        choices={statuses}
        choiceLabelField="name"
        choiceValueField="id"
        source="status"
        alwaysOn
        minWidth
        resettable
      />
    </Filter>
  );
};

const SyncStatusField = ({ record }) => {
  const translate = useTranslate();
  if (record) {
    if (record.error) {
      return (
        <Tooltip disableFocusListener disableTouchListener title={`${translate('telemedic.aisErpIntegration.list.error')} - ${record.error}`}>
          <span><PriorityHighIcon color="error" /></span>
        </Tooltip>
      );
    }
    if (record.ais_erp_checkup_id) {
      const formattedTime = record.datetime_send ? formatDisplayDateTime(record.datetime_send) : '-'
      const sentAt = `${translate('telemedic.aisErpIntegration.list.datetimeSend')}: ${formattedTime}`;
      return (
        <Tooltip disableFocusListener disableTouchListener title={sentAt}>
          <span><CheckIcon color="primary" /></span>
        </Tooltip>
      );
    }
  }
  return null;
};

export default AisErpList;
